import algoliasearch from 'algoliasearch';
import qs from 'qs';
import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { HiddenFacets } from './hidden-facets';
import { assert, isEmpty } from '../util';
const DEBOUNCE_TIME = 100;
const defaults = {};
if (typeof window != 'undefined' && window.WCC && window.WCC.CONSTANTS) {
    const wcc = window.WCC;
    const constants = wcc.CONSTANTS;
    defaults.apiKey = constants.ALGOLIA_SEARCH_API_KEY;
    defaults.appId = constants.ALGOLIA_APP_ID;
    if (wcc.algoliaProxy) {
        defaults.hosts = [wcc.algoliaProxy];
    }
}
else {
    defaults.apiKey = 'server-rendering';
    defaults.appId = 'server-rendering';
}
export default class AlgoliaSearch extends React.Component {
    static defaultProps = {
        ...defaults,
        searchStateToUrl: searchStateToUrl,
        urlToSearchState: urlToSearchState,
    };
    /**
     * On initial load, parse out the searchState from the URL.
     */
    static getDerivedStateFromProps(props, state) {
        if (!props.storeStateInQuery) {
            return state;
        }
        if (typeof window == 'undefined' ||
            window.location.href === state.lastLocation) {
            return state;
        }
        return {
            searchState: props.urlToSearchState?.(window.location),
            lastLocation: window.location.href,
        };
    }
    client;
    debouncedSetState;
    constructor(props) {
        super(props);
        const opts = {
            logger: console
        };
        if (props.hosts) {
            Object.assign(opts, {
                hosts: props.hosts
            });
        }
        assert(props.appId, 'props.appId is missing');
        assert(props.apiKey, 'props.apiKey is missing');
        this.client = algoliasearch(props.appId, props.apiKey, opts);
        if (typeof window == 'undefined') {
            this.state = {
                searchState: {},
            };
            return;
        }
        this.state = {
            searchState: props.storeStateInQuery ? props.urlToSearchState?.(window.location) : undefined,
            lastLocation: window.location.href,
        };
    }
    render() {
        const { 
        /* eslint-disable @typescript-eslint/no-unused-vars */
        apiKey, appId, 
        /* eslint-enable @typescript-eslint/no-unused-vars */
        widgetName, storeStateInQuery, initialState, initialResults, ...props } = this.props;
        let resultsState = undefined;
        if (initialResults) {
            resultsState = {
                state: {
                    index: props.indexName,
                    ...initialState
                },
                rawResults: [initialResults],
                // Metadata is set by individual widgets.  To capture this, we'd have to approach the problem in a different way.
                // This does not affect hidden widgets, only things like displayed CurrentRefinements badges & RefinementLists.
                metadata: []
            };
        }
        return (React.createElement("div", { className: widgetName },
            React.createElement(InstantSearch, { searchState: storeStateInQuery ? this.state.searchState : undefined, onSearchStateChange: this.onSearchStateChange, createURL: storeStateInQuery && typeof ('window') != 'undefined' ? createURL : undefined, ...props, searchClient: this.client, resultsState: resultsState }, this.props.children)));
    }
    componentDidMount = () => {
        const { searchState } = this.state;
        if (this.props.storeStateInQuery) {
            window.addEventListener('popstate', this.onPopStateHandler);
        }
        if (this.props.onSearchStatechange && searchState && !isEmpty(searchState)) {
            this.props.onSearchStatechange(searchState);
        }
    };
    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onPopStateHandler);
    };
    /*
    * The magic happens here -
    * look at this tutorial: https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
    */
    /**
     * Update the current URL whenever the search state changes
     */
    onSearchStateChange = (searchState) => {
        clearTimeout(this.debouncedSetState);
        if (this.props.storeStateInQuery) {
            // If storeStateInQuery, we control the searchState in the wrapper
            // so have to keep it updated every time (i.e. outside debounce)
            this.setState({ searchState: searchState });
            if (typeof window != 'undefined' && this.props.searchStateToUrl) {
                this.debouncedSetState = setTimeout(() => {
                    const url = this.props.searchStateToUrl?.(window.location, searchState);
                    window.history.replaceState(searchState, document.title, url);
                    this.setState({
                        searchState,
                        lastLocation: url,
                    });
                }, DEBOUNCE_TIME);
            }
        }
        if (this.props.onSearchStatechange) {
            this.props.onSearchStatechange(searchState);
        }
    };
    /**
     * Called when the URL was changed outside of the control of AlgoliaSearch,
     * without a page refresh.
     * ex. hitting "back" from bootstrap tabs
     */
    onPopStateHandler = (evt) => {
        const state = evt.state;
        if (!state || !isSearchState(state)) {
            return;
        }
        clearTimeout(this.debouncedSetState);
        this.debouncedSetState = setTimeout(() => {
            this.setState({
                searchState: state,
                lastLocation: this.props.searchStateToUrl?.(location, state),
            });
        }, DEBOUNCE_TIME);
    };
}
/**
 * Turn an Algolia search state into a relative URL for a hyperlink
 */
const createURL = (state) => `?${qs.stringify(state)}${window.location.hash}`;
/*
 * Create an absolute URL suitable for the history from the current location and search state.
 */
function searchStateToUrl(location, searchState) {
    if (!searchState) {
        return '';
    }
    searchState = {
        ...searchState,
    };
    // "configure" is not something that the user can toggle but is rather set by
    // search tabs.
    delete (searchState.configure);
    // since we use InfiniteSearchResults everywhere, having page doesn't help
    delete (searchState.page);
    return new URL(createURL(searchState), location.toString()).toString();
}
/**
 * Parse the current search state out of a URL.
 */
function urlToSearchState(location) { return qs.parse(location.search.slice(1)); }
function isSearchState(state) {
    return 'page' in state;
}
export { AlgoliaSearch, HiddenFacets, };
